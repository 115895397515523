import { Splide } from '@splidejs/splide';

class CardProduct extends HTMLElement {
  constructor() {
    super();
    this.info = this.querySelector('.js-card-info');
    this.mobileSlide = this.querySelector('[data-mobile-slider]');
    this.desktopSlide = this.querySelector('[data-desktop-slider]');
    this.isMobile = window.innerWidth < 768;
    this.observer = null;

    const sliderConfig = {
      type: 'loop',
      rewind: false,
      arrows: true,
      pagination: false,
      lazyLoad: true,
      drag: true,
      arrowPath: 'm2.2742.9395 20 19.9999-20 20.0001-2.1149-2.115 17.8851-17.8851-17.8851-17.885 2.1149-2.1149z',
      classes: {
        arrow: 'splide__arrow cs-arrow',
        arrows: 'splide__arrows opacity-0 group-hover:opacity-100',
        // Add classes for pagination.
        page: 'splide__pagination-card-product' // each button
      },
      breakpoints: {
        768: {
          arrows: false,
          pagination: true
        }
      }
    };

    // Initialize mobile slider
    if (this.mobileSlide) {
      this.mobileSlider = new Splide(this.mobileSlide, sliderConfig);
    }

    // Initialize desktop slider
    if (this.desktopSlide) {
      this.desktopSlider = new Splide(this.desktopSlide, sliderConfig);
    }

    // Handle window resize
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.handleResize);
  }

  initializeMobileObserver() {
    if (this.mobileSlide && this.isMobile) {
      const options = {
        root: null,
        rootMargin: '50px',
        threshold: 0.1
      };

      // Clean up existing observer if it exists
      if (this.observer) {
        this.observer.disconnect();
      }

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (!this.mobileSlider.state.is(Splide.STATES.IDLE)) {
              if (!this.mobileSlider.state.is(Splide.STATES.MOVING)) {
                this.mobileSlider.mount();
              }
            }
            this.observer.unobserve(entry.target);
          }
        });
      }, options);

      this.observer.observe(this.mobileSlider.root);
    }
  }

  handleResize() {
    const wasMobile = this.isMobile;
    this.isMobile = window.innerWidth < 768;

    // If we've switched to mobile from desktop
    if (!wasMobile && this.isMobile) {
      // Reinitialize the mobile observer
      this.initializeMobileObserver();
    }
  }

  connectedCallback() {
    if (this.info) {
      // Default text color
      this.info.classList.add('lg:text-brand-primary-100');
      this.info.classList.remove('lg:text-white');
    }

    // Desktop: Initialize slider on hover
    if (this.desktopSlide) {
      this.querySelector('.splide__slide__container').addEventListener('mouseover', (event) => {
        if (!this.desktopSlider.state.is(Splide.STATES.IDLE)) {
          if (!this.desktopSlider.state.is(Splide.STATES.MOVING)) {
            this.desktopSlider.mount();
          }
        }
      });
    }

    // Initialize mobile observer
    this.initializeMobileObserver();
  }

  disconnectedCallback() {
    if (this.mobileSlider) {
      this.mobileSlider.destroy();
    }
    if (this.desktopSlider) {
      this.desktopSlider.destroy();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener('resize', this.handleResize);
  }
}

customElements.define('card-product', CardProduct);

/**
 * Background Check Feature Documentation
 *
 * This feature allows dynamic text color adjustment based on the background image brightness.
 * To implement this feature:
 *
 * 1. Add the background-check attribute to card-product.liquid:
 *
 * <card-product
 *   class="w-full h-auto splide__slide aspect-auto"
 *   background-check="
 *     {% case media.media_type %}
 *       {% when 'image' %}
 *       {{ media | image_url: width: 100 }}
 *       {% when 'video' %}
 *       video
 *     {% else %}
 *       {{ product.images[1] | image_url: width: 100 }}
 *     {% endcase %}
 *   ">
 *
 * 2. Add the following code to the CardProduct class:
 *
 * constructor() {
 *   // Add this line after super()
 *   this.image = this.getAttribute('background-check');
 * }
 *
 * connectedCallback() {
 *   if (this.info) {
 *     if (this.image && this.image !== 'video') {
 *       this.checkBottomCorners(this.image, this.info);
 *     } else {
 *       this.info.classList.add('lg:text-brand-primary-100');
 *       this.info.classList.remove('lg:text-white');
 *     }
 *   }
 * }
 *
 * checkBottomCorners(imageUrl, target) {
 *   const img = new Image();
 *   img.src = imageUrl;
 *   img.crossOrigin = 'Anonymous'; // Required for CORS images
 *   img.onload = function () {
 *     const canvas = document.createElement('canvas');
 *     const ctx = canvas.getContext('2d');
 *     canvas.width = img.width;
 *     canvas.height = img.height;
 *
 *     ctx.drawImage(img, 0, 0);
 *
 *     // Get bottom-left pixel data
 *     const bottomLeftPixel = ctx.getImageData(0, img.height - 1, 1, 1).data;
 *
 *     // Get bottom-right pixel data
 *     const bottomRightPixel = ctx.getImageData(img.width - 1, img.height - 1, 1, 1).data;
 *
 *     // Convert to grayscale
 *     const bottomLeftGray = 0.3 * bottomLeftPixel[0] + 0.59 * bottomLeftPixel[1] + 0.11 * bottomLeftPixel[2];
 *     const bottomRightGray = 0.3 * bottomRightPixel[0] + 0.59 * bottomRightPixel[1] + 0.11 * bottomRightPixel[2];
 *
 *     // Check if grayscale values are closer to black or white
 *     const threshold = 128; // Midpoint between 0 and 255
 *     const bottomLeftIsBlack = bottomLeftGray < threshold;
 *     const bottomRightIsBlack = bottomRightGray < threshold;
 *
 *     // Determine result
 *     let result = {};
 *     if (bottomLeftIsBlack && bottomRightIsBlack) {
 *       result = {
 *         areSame: true,
 *         color: 'black'
 *       };
 *     } else if (!bottomLeftIsBlack && !bottomRightIsBlack) {
 *       result = {
 *         areSame: true,
 *         color: 'white'
 *       };
 *     } else {
 *       result = {
 *         areSame: false,
 *         color: 'mixed'
 *       };
 *     }
 *
 *     if (result.color == 'white') {
 *       target.classList.add('lg:text-brand-primary-100');
 *       target.classList.remove('lg:text-white');
 *     } else {
 *       target.classList.remove('lg:text-brand-primary-100');
 *       target.classList.add('lg:text-white');
 *     }
 *   };
 * }
 */
